<template>
  <div eagle-tinymce="root">
    <v-overlay
      eagle-tinymce="loading"
      absolute
      opacity="0.2"
      :value="loading"
      :style="{ zIndex: $zIndexConfig.htmlEditorLoading }"
    >
      <v-progress-circular indeterminate size="80"></v-progress-circular>
    </v-overlay>

    <v-progress-linear
      :indeterminate="loading"
      :color="progressLinearClass"
    ></v-progress-linear>

    <tinymce
      @onInit="onInit"
      :apiKey="apiKey"
      :disabled="loading"
      v-model="data"
      :init="tinymceOptions"
    ></tinymce>

    <!-- 圖片上傳元件 -->
    <invisible-photo-uploader
      @onLoadingChange="setLoading"
      @appendPhoto="onPhotoUploadSuccess"
      ref="photoUploader"
      :multiple="multiplePhotoUpload"
      :type="photoType"
    ></invisible-photo-uploader>

    <!-- 檔案上傳元件 -->
    <invisible-file-uploader
      @onLoadingChange="setLoading"
      @appendFile="insertFile"
      ref="fileUploader"
      multiple
    ></invisible-file-uploader>
  </div>
</template>

<script>
// https://www.tiny.cloud/docs/integrations/vue
import tinymce from '@tinymce/tinymce-vue'
import { tinymceApiKey } from 'config/env.json'
export default {
  props: {
    value: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    photoType: {
      type: String,
      default: 'default',
    },
    multiplePhotoUpload: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    data: null,
    editor: null,
    loading: false,
    photoUploadCallback: {
      success: null,
      failure: null,
    },
  }),
  created() {
    this.loading = true
    this.data = this.value
  },
  methods: {
    onInit() {
      this.loading = false
    },
    tinymceSetup(editor) {
      this.editor = editor

      // 一次上傳多張圖
      this.editor.ui.registry.addButton('eagleUploadMultiplePhoto', {
        tooltip: this.$t('action.tinymce.upload_photos'),
        icon: 'duplicate',
        onAction: () => this.$refs.photoUploader.openFileBrowser(),
      })

      // 重點文字
      this.editor.ui.registry.addButton('eagleMark', {
        tooltip: this.$t('action.tinymce.mark'),
        icon: 'permanent-pen',
        onAction: () => {
          this.$apopup.prompt({
            title: this.$t('action.tinymce.mark'),
            promptMaxLength: 50,
            width: '400px',
            disabledApply: (data) => this.$helper.textEmpty(data),
            applyCallback: (data) => {
              this.editor.insertContent(`<span class="primary--text">${data}</span>`)
            },
          })
        }
      })

      // code
      this.editor.ui.registry.addButton('eagleCode', {
        tooltip: this.$t('html-editor.action.kbd'),
        icon: 'sharpen',
        onAction: () => {
          this.$apopup.prompt({
            title: this.$t(`html-editor.action.kbd`),
            applyCallback: data => {
              this.editor.insertContent(`<kbd>${data}</kbd>`)
            },
          })
        }
      })

      // 檔案上傳
      this.editor.ui.registry.addButton('eagleUploadFile', {
        tooltip: this.$t('file.action.upload'),
        icon: 'upload',
        onAction: () => {
          this.$refs.fileUploader.openFileBrowser()
        },
      })

      // 圖片管理
      this.editor.ui.registry.addButton('eaglePhotoManager', {
        text: this.$t('photo_manager'),
        onAction: () => {
          this.$photoSelector({
            max: 10,
            multiple: true,
            applyCallback: (data) => {
              this.insertPhotos(data.map(item => item.photo))
            },
          })
        },
      })

      // 檔案管理
      this.editor.ui.registry.addButton('eagleFileManager', {
        text: this.$t('file_manager'),
        onAction: () => {
          this.$fileSelector({
            max: 10,
            multiple: true,
            applyCallback: (data) => {
              for(const item of data) {
                this.insertFile(item.file)
              }
            },
          })
        },
      })
    },
    setLoading(status) {
      this.loading = status
    },
    onPhotoUploadSuccess(result) {
      if(typeof this.photoUploadCallback.success == 'function') {
        this.photoUploadCallback.success(this.$helper.getPhotoUrl(result, ['origin']))
        this.resetPhotoUploadCallback()
        return
      }

      this.insertPhotos([result])
    },
    insertFile(file) {
      const url = this.$helper.getFileUrl(file)
      this.editor.insertContent(`<p><a href="${url}" target="_blank">${file.filename}</a></p>`)
    },
    insertPhotos(photos) {
      if(!Array.isArray(photos)) return
      if(photos.length == 0) return
      for(const photo of photos) {
        const photoUrl = this.$helper.getPhotoUrl(photo, ['origin'])
        if(!photoUrl) continue
        const alt = photo.name || ''
        this.editor.insertContent(`<p><img src="${photoUrl}" loading="lazy" alt="${alt}" /></p>`)
      }
    },
    resetPhotoUploadCallback() {
      this.photoUploadCallback.success = null
      this.photoUploadCallback.failure = null
    },
    imagesUploadHandler(blobInfo, success, failure) {
      this.photoUploadCallback.success = success
      this.photoUploadCallback.failure = failure
      const file = blobInfo.blob()
      this.$refs.photoUploader.handleFileChange([file])
    },
  },
  computed: {
    eagleMediaTools() {
      return `eagleFileManager eaglePhotoManager`
    },
    customTools() {
      return `eagleUploadMultiplePhoto eagleUploadFile eagleMark eagleCode`
    },
    tinymceLanguage() {
      return this.$i18n.locale.replace('-', '_')
    },
    progressLinearClass() {
      if(!this.loading) return 'white'
      return `primary lighten-1`
    },
    apiKey() {
      return tinymceApiKey
    },
    primaryColor() {
      return this.$vuetify.theme.themes.light.primary
    },
    tinymceOptions() {
      let options = {
        min_height: 450,
        toolbar: this.toolbar,
        plugins: this.plugins,
        language: this.tinymceLanguage,
        language_url: `/tinymce/language/${this.tinymceLanguage}.js`,
        images_upload_handler: this.imagesUploadHandler,
        extended_valid_elements: `span[class|color|style]`,
        setup: this.tinymceSetup,
        default_link_target: '_blank',
        content_style: `
        .primary--text { color: ${this.primaryColor} !important; font-weight: 500; }
        kbd { background-color: #616161; color: #FFFFFF; font-weight: 600; border-radius: 3px; font-family: monospace, monospace; font-size: 85%; white-space: pre-wrap; padding: 1px 5px }
        `,
      }

      return options
    },
    plugins() {
      return [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code table',
      ]
    },
    toolbar() {
      return `
    fullscreen code | undo redo | anchor link image ${this.customTools}| media | styleselect | forecolor backcolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent |
    table | ${this.eagleMediaTools}
    `
    },
  },
  watch: {
    value() {
      this.data = this.value
    },
    data() {
      this.$emit('input', this.data)
    },
  },
  components: {
    tinymce,
  },
}
</script>

<style lang="sass" type="text/sass">
div[eagle-tinymce="root"]
  position: relative
  min-height: 300px
  *[eagle-tinymce="loading"]
    width: 100%
    height: 100%
</style>
